<template>
  <div class="right-sidebar-course-steps flex-column jc-sb default-card">
    <section class="overflow-y-auto flex-column">
    <div class="right-sidebar-course-steps__wrapper border-bottom flex-column gap-2 w-100">
        <div class="flex-column">
          <Typography variant="custom" textSize="18px">You’re signed up to this course</Typography>
          <Typography variant="p" textColor="rgba(12, 15, 74, 0.50)" textWeight="500">
            Lorem ipsum dolor sit amet consectetur. Tincidunt vitae habitasse adipiscing.
          </Typography>
        </div>
        <div class="w-100 flex-row ai-c jc-fs">
          <!-- <CourseTimeState time="22:42"/> -->
          <Typography variant="p" textColor="#FFA500" v-if="hasSavedDate">
            {{ getReadableDate }}
          </Typography>
        </div>
    </div>
     <el-steps :active="getStepIndex" :space="130" direction="vertical">
        <el-step v-for="(step, index) in stepsData" :key="index">
          <template #icon>
            <div
              class="right-sidebar-course-steps__icon-bg flex-row ai-c jc-c"
              :class="`status-${getStatus(index)}`">
              <el-icon :size="15" color="#FFF" v-if="getStatus(index) === 'success'">
                <Check/>
              </el-icon>
              <el-icon :size="15" color="#FFF" v-else><HomeFilled/></el-icon>
            </div>
          </template>
          <template #title>
            <Typography
              class="right-sidebar-course-steps__title"
              :class="`status-${getStatus(index)}`"
              variant="custom"
              textSize="18px">
                {{ step.title }}
            </Typography>
          </template>
          <template #description>
            <div class="flex-column gap-half w-100">
              <Typography
                class="right-sidebar-course-steps__description"
                :class="`status-${getStatus(index)}`"
                variant="custom"
                textSize="12px"
                textWeight="500">
                {{ step.description }}
              </Typography>
              <ActionItemComponent
                v-if="step.hasAction"
                @call-to-action="handleClick"
                :label="step?.actionLabel"
                :isReverse="true"
                fontColor="#FFA500"
                icon="arrow-right.svg"/>
            </div>
          </template>
        </el-step>
      </el-steps>
    </section>
    <section class="flex-column w-100">
        <!-- <MemberCounter
            v-if="members.length"
            :visibleMembers="visibleMembers"
            :remainingMembersCount="remainingMembersCount"/> -->
        <Button
          class="w-100"
          buttonText="xxx"
          :isActive="true"/>
    </section>
  </div>
</template>
<script>
import { Check, HomeFilled } from '@element-plus/icons';
import { defineComponent } from 'vue';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import Typography from '@/core/components/ui/Typography.vue';
import appFilters from '@/filters';
// import CourseTimeState from '@/modules/courses-children/components/CourseTimeState.vue';
// import MemberCounter from '@/modules/courses-children/components/MemberCounter.vue';

export default defineComponent({
  components: {
    Button,
    // CourseTimeState,
    Typography,
    HomeFilled,
    ActionItemComponent,
    Check,
    // MemberCounter
  },

  props: ['hasNoSavedDate', 'hasSavedDate', 'hasCompleted', 'currentCourse'],

  data() {
    return {
      stepsData: [
        {
          title: 'Pre-Enrolled',
          description: 'Lorem ipsum dolor sit amet consectetur. Tincidunt vitae habitasse adipiscing.'
        },
        {
          title: 'Enrolled',
          description: 'Lorem ipsum dolor sit amet consectetur. Tincidunt vitae habitasse adipiscing.',
          hasAction: true,
          actionLabel: 'Relevant link'
        },
        {
          title: 'Awaiting Results',
          description: 'Lorem ipsum dolor sit amet consectetur. Tincidunt vitae habitasse adipiscing.'
        },
        {
          title: 'Results Awarded',
          description: 'Lorem ipsum dolor sit amet consectetur. Tincidunt vitae habitasse adipiscing.'
        },
      ],

      activeIndex: 1, // Current active index or current on process index. Index starts with 0...

      members: [
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
      ],
    };
  },

  computed: {
    visibleMembers() {
      return this.members.slice(0, 5);
    },
    remainingMembersCount() {
      return Math.max(0, this.members.length - 5);
    },

    getStepIndex() {
      if (this.hasNoSavedDate) return 0;
      if (this.hasSavedDate) return 1;
      if (this.hasCompleted) return 4;
      return 3;
    },

    getReadableDate() {
      let readableDate = '';

      if (this.currentCourse?.selectedStartDate) {
        readableDate = appFilters.formatToDate(this.currentCourse?.selectedStartDate, 'Do MMM YYYY');
      }

      return readableDate;
    }
  },

  methods: {
    handleClick() {},

    getStatus(index) {
      const { getStepIndex } = this;

      if (index === getStepIndex) {
        return 'process';
      }

      if (index < getStepIndex) {
        return 'success';
      }

      return 'wait';
    },
  },
});
</script>
<style lang="scss" scoped>
.right-sidebar-course-steps {
    max-height: unset !important;
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08) !important;

    &__icon-bg {
      background: #0C0F4A;
      width: 100%;
      height: 100%;
      border-radius: 100px;
    }

    &__icon-bg.status-wait {
      background: rgba(12, 15, 74, 0.50) !important;
    }

    &__icon-bg.status-success {
      background: #1FB27A !important;
    }

    &__title.status-wait,
    &__description.status-wait {
      color: rgba(12, 15, 74, 0.50) !important;
    }

    &__title.status-success,
    &__description.status-success {
      color: #1FB27A !important;
    }
}

:deep(.el-step__icon) {
    width: 30px;
    height: 30px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
}

:deep(.el-step__line) {
    top: 2px;
    left: 14px;
}

@media screen and (min-height: 1145px) {
  .right-sidebar-course-steps {
    max-height: 885px !important;
  }
}
</style>
