<template>
    <div class="air-source-template flex-column gap-3">
      <section class="flex-column w-100">
        <Typography variant="h4" whiteSpace="nowrap">
          Unvented Hot Water
        </Typography>
        <div class="flex-row ai-c jc-sb">
          <!-- <CourseTimeState time="22:42" v-if="!hasSignUp"/> -->
          <div>
            <CourseStatusState
              :isEnrolled="hasSignUp"
              style="max-width: 150px !important"
              :label="getStatusLabel(course)"
              v-if="currentCourse.isUpcoming"/>
          </div>
          <div class="flex-row ai-c">
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="currency-filled-green.svg"
              textColor="#1FB27A"
              :label="$filters.formatToCurrency('220')"
              labelSpan="FREE"
              :crashLabel="true"/>
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="location-filled-blue.svg"
              textColor="#264FD5"
              label="Online & In-Person"/>
          </div>
        </div>
        <div class="air-source-template__banner-container w-100">
          <img class="air-source-template__img" src="@/assets/images/water-regulation-hero.jpg" alt="iknowa-image">
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          About
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(about, index) in abouts" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ about }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Course aims
        </Typography>
        <div class="flex-column gap-half">
            <Typography variant="p" textWeight="600" textColor="rgba(12, 15, 74, 0.80)">
              There are a number of modules in the course to ensure that you complete it fully qualified, confident and are able to work safely with Unvented Hot Water Systems including:
            </Typography>
            <Typography
              v-for="(aim, index) in courseAims" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ aim }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Prerequisites
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(prerequisite, index) in prerequisites" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ prerequisite }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          After completing this course you will be able to
        </Typography>
        <div class="flex-column gap-half">
          <div class="flex-row ai-c" v-for="(skill, index) in attainSkills" :key="index">
            <Icon iconName="check-circle-filled.svg" customPath="icons/courses-icons"/>
            <Typography variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ skill }}
            </Typography>
          </div>
        </div>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ChipState from '@/core/components/ui/ChipState.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import CourseStatusState from '@/modules/courses-children/components/CourseStatusState.vue';
// import CourseTimeState from '@/modules/courses-children/components/CourseTimeState.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    Icon,
    Typography,
    ChipState,
    // CourseTimeState,
    CourseStatusState
  },

  props: ['hasSignUp', 'timeIsUp'],

  data() {
    return {
      abouts: [
        'This course was developed to enable plumbing and heating engineers to confirm their competence in installing and maintaining unvented hot water storage systems. On successful completion of this course, it will allow candidates’ membership to an Unvented Hot Water Competent Persons Scheme.',
        'It is illegal to work on unvented hot water systems with capacities of over 15l, unless you hold a recognised qualification.',
        'Be able to expand their range of services. Operatives will be recognised as being competent to design, install, service and maintain unvented water systems and therefore able to self-certify their own work and comply to Part G Building Regulations.'
      ],
      courseAims: [
        'Regulations, standards and guidelines',
        'Theory of hot water expansion',
        'Principles of hot water storage',
        'Installation requirements',
        'Design considerations',
        'Components and function',
        'Pipework and testing',
        'Safety controls, discharge and integration',
        'Maintenance, commissioning and servicing'
      ],
      prerequisites: [
        'Recommended Water Regs / No experience'
      ],
      attainSkills: [
        'Obtain HIES Consumer code certificate',
        'Join MCS as a certified installer',
        'Offer solar pv installation & maintenance services',
      ],
    };
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),
  },

  methods: {
    getStatusLabel() {
      if (this.hasSignUp) {
        return "You're Enrolled";
      }
      return "You're Pre-Enrolled";
    }
  }
});
</script>
<style lang="scss" scoped>
.air-source-template {
  padding-bottom: 2rem;
  box-sizing: border-box;

  &__banner-container {
    max-height: 235px;
    min-height: 235px;
    border-radius: 8px;
    overflow: hidden;
  }
  &__img {
    object-fit: cover;
    height: 100%;
    width:100%;
  }
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    padding-left: 1rem;
    box-sizing: border-box;
  }
}
</style>
