<template>
    <div class="air-source-template flex-column gap-3">
      <section class="flex-column w-100">
        <Typography variant="h4" whiteSpace="nowrap">
          Water Regulations
        </Typography>
        <div class="flex-row ai-c jc-sb">
          <!-- <CourseTimeState time="22:42" v-if="!hasSignUp"/> -->
          <div>
            <CourseStatusState
              :isEnrolled="hasSignUp"
              style="max-width: 150px !important"
              :label="getStatusLabel()"
              v-if="currentCourse.isUpcoming"/>
          </div>
          <div class="flex-row ai-c">
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="currency-filled-green.svg"
              textColor="#1FB27A"
              :label="$filters.formatToCurrency('220')"
              labelSpan="FREE"
              :crashLabel="true"/>
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="location-filled-blue.svg"
              textColor="#264FD5"
              label="Online & In-Person"/>
          </div>
        </div>
        <div class="air-source-template__banner-container w-100">
          <img class="air-source-template__img" src="@/assets/images/water-regulation-hero.jpg" alt="iknowa-image">
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          About
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(about, index) in abouts" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ about }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Course aims
        </Typography>
        <div class="flex-column gap-half">
            <Typography variant="p" textWeight="600" textColor="rgba(12, 15, 74, 0.80)">
              The Water Regulations training covers the following topics:
            </Typography>
            <Typography
              v-for="(aim, index) in courseAims" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ aim }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Prerequisites
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(prerequisite, index) in prerequisites" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ prerequisite }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          After completing this course you will be able to
        </Typography>
        <div class="flex-column gap-half">
          <div class="flex-row ai-c" v-for="(skill, index) in attainSkills" :key="index">
            <Icon iconName="check-circle-filled.svg" customPath="icons/courses-icons"/>
            <Typography variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ skill }}
            </Typography>
          </div>
        </div>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ChipState from '@/core/components/ui/ChipState.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import CourseStatusState from '@/modules/courses-children/components/CourseStatusState.vue';
// import CourseTimeState from '@/modules/courses-children/components/CourseTimeState.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    Icon,
    Typography,
    ChipState,
    // CourseTimeState,
    CourseStatusState
  },

  props: ['hasSignUp', 'timeIsUp'],

  data() {
    return {
      abouts: [
        'This course is for heating and plumbing installers who need to gain the knowledge of the Water Regulations (1999) before applying to join their local water authorities “Water Industry Approved Plumber Scheme”, one of the plumbing competent person schemes (CPS) or Microgeneration Certification Scheme (MCS) registration bodies.'
      ],
      courseAims: [
        'Water Regulations; interpretation and definitions',
        'Materials and substances',
        'Requirements design and installation',
        'Back flow and contamination prevention',
        'Hot and cold –water services',
        'Flushing Devices',
        'Sanitary appliances, both internal and external The course is fully recognised by WRAS as meeting their qualification requirements.',
        'A WRAS certificate and ID card will be issued by Blueflame Certification to successful candidates.',
      ],
      prerequisites: [
        'Gas or Plumbing level 2 minimum'
      ],
      attainSkills: [
        'Apply for Air Source Heat Pump Level 3 course (Subject to holding Energy Efficiency for Domestic Heating)',
      ],
    };
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),
  },

  methods: {
    getStatusLabel() {
      if (this.hasSignUp) {
        return "You're Enrolled";
      }
      return "You're Pre-Enrolled";
    }
  }
});
</script>
<style lang="scss" scoped>
.air-source-template {
  padding-bottom: 2rem;
  box-sizing: border-box;

  &__banner-container {
    max-height: 235px;
    min-height: 235px;
    border-radius: 8px;
    overflow: hidden;
  }
  &__img {
    object-fit: cover;
    height: 100%;
    width:100%;
  }
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    padding-left: 1rem;
    box-sizing: border-box;
  }
}
</style>
