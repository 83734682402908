<template>
    <div class="air-source-template flex-column gap-3">
      <section class="flex-column w-100">
        <Typography variant="h4" whiteSpace="nowrap">
          Air Source Heat Pump Level 3
        </Typography>
        <div class="flex-row ai-c jc-sb">
          <!-- <CourseTimeState time="22:42" v-if="!hasSignUp"/> -->
          <div>
            <CourseStatusState
              :isEnrolled="hasSignUp"
              style="max-width: 150px !important"
              :label="getStatusLabel(course)"
              v-if="currentCourse.isUpcoming"/>
          </div>
          <div class="flex-row ai-c">
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="currency-filled-green.svg"
              textColor="#1FB27A"
              :label="$filters.formatToCurrency('220')"
              labelSpan="FREE"
              :crashLabel="true"/>
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="location-filled-blue.svg"
              textColor="#264FD5"
              label="Online & In-Person"/>
          </div>
        </div>
        <div class="air-source-template__banner-container w-100">
          <img class="air-source-template__img" src="@/assets/images/sample-picture-2.jpg" alt="iknowa-image">
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          About
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(about, index) in abouts" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ about }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Course aims
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(aim, index) in courseAims" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ aim }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          After completing this course you will be able to
        </Typography>
        <div class="flex-column gap-half">
          <div class="flex-row ai-c" v-for="(skill, index) in attainSkills" :key="index">
            <Icon iconName="check-circle-filled.svg" customPath="icons/courses-icons"/>
            <Typography variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ skill }}
            </Typography>
          </div>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Course Details
        </Typography>
        <div class="air-source-template__wrapper grid">
          <CourseDetailCard v-for="(detail, index) in courseDetails" :key="index" :detail="detail"/>
        </div>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ChipState from '@/core/components/ui/ChipState.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import CourseDetailCard from '@/modules/courses-children/components/CourseDetailCard.vue';
import CourseStatusState from '@/modules/courses-children/components/CourseStatusState.vue';
// import CourseTimeState from '@/modules/courses-children/components/CourseTimeState.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    Icon,
    Typography,
    ChipState,
    // CourseTimeState,
    CourseDetailCard,
    CourseStatusState
  },

  props: ['hasSignUp', 'timeIsUp'],

  data() {
    return {
      abouts: [
        'This course is designed to allow qualified plumbers and heating engineers to gain the knowledge and skills needed to install Air source heat pumps either by installing new installations or retro fitting existing installations. Completing this course will allow installers to apply and join the “Consumer Code” and “MCS” Scheme',
      ],
      courseAims: [
        'This qualification prepares learners who are looking towards a career in Air Source Heat Pump Installation and gaining the necessary skills for the design, installation, testing, commissioning, handover, servicing and fault-finding of ground and air source heat pump systems in accordance with the latest NOS/QCF criteria and MCS scheme requirements.',
      ],
      courseDetails: [
        {
          days: '5',
          sight: 'Domestic Workshop',
          title: 'Minor Electrical Works',
          description: 'Digital Credential – Minor Electrical Works'
        },
        {
          days: '1',
          sight: 'Classroom',
          title: 'Water Regulations',
          description: 'LCL Awards Water Regulations'
        },
        {
          days: '3',
          sight: 'Heat Pump Workshop',
          title: 'Heat Pumps',
          description: 'LCL Award Level 3 Award in the Installation and Maintenance of Heat Pump Systems (non-refrigerant circuits)'
        },
        {
          days: '1',
          sight: 'Classroom',
          title: 'Introduction to Domestic Retrofit',
          description: 'Digital Credential – Introduction to Domestic Retrofit'
        },
      ],
      attainSkills: [
        'Obtain HIES Consumer code certificate',
        'Join MCS as a certified installer',
        'Offer heat pump installation services'
      ],
    };
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),
  },

  methods: {
    getStatusLabel() {
      if (this.hasSignUp) {
        return "You're Enrolled";
      }
      return "You're Pre-Enrolled";
    }
  }
});
</script>
<style lang="scss" scoped>
.air-source-template {
  padding-bottom: 2rem;
  box-sizing: border-box;

  &__banner-container {
    max-height: 235px;
    min-height: 235px;
    border-radius: 8px;
    overflow: hidden;
  }
  &__img {
    object-fit: cover;
    height: 100%;
    width:100%;
  }
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    padding-left: 1rem;
    box-sizing: border-box;
  }
}
</style>
