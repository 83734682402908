<template>
    <div class="air-source-template flex-column gap-3">
      <section class="flex-column w-100">
        <Typography variant="h4" whiteSpace="nowrap">
          Solar PV Installation &amp; Maintenance
        </Typography>
        <div class="flex-row ai-c jc-sb">
          <!-- <CourseTimeState time="22:42" v-if="!hasSignUp"/> -->
          <div>
            <CourseStatusState
              :isEnrolled="hasSignUp"
              style="max-width: 150px !important"
              :label="getStatusLabel(course)"
              v-if="currentCourse.isUpcoming"/>
          </div>
          <div class="flex-row ai-c">
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="currency-filled-green.svg"
              textColor="#1FB27A"
              :label="$filters.formatToCurrency('220')"
              labelSpan="FREE"
              :crashLabel="true"/>
            <ChipState
              iconCustomPath="icons/courses-icons"
              iconName="location-filled-blue.svg"
              textColor="#264FD5"
              label="Online & In-Person"/>
          </div>
        </div>
        <div class="air-source-template__banner-container w-100">
          <img class="air-source-template__img" src="@/assets/images/solar-pv-installation-hero.jpg" alt="iknowa-image">
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          About
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(about, index) in abouts" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ about }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Course aims
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(aim, index) in courseAims" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ aim }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          Prerequisites
        </Typography>
        <div class="flex-column gap-half">
            <Typography
              v-for="(prerequisite, index) in prerequisites" :key="index"
              variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ prerequisite }}
            </Typography>
        </div>
      </section>
      <section class="flex-column">
        <Typography variant="custom" textSize="18px">
          After completing this course you will be able to
        </Typography>
        <div class="flex-column gap-half">
          <div class="flex-row ai-c" v-for="(skill, index) in attainSkills" :key="index">
            <Icon iconName="check-circle-filled.svg" customPath="icons/courses-icons"/>
            <Typography variant="p" textWeight="400" textColor="rgba(12, 15, 74, 0.80)">
              {{ skill }}
            </Typography>
          </div>
        </div>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ChipState from '@/core/components/ui/ChipState.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import CourseStatusState from '@/modules/courses-children/components/CourseStatusState.vue';
// import CourseTimeState from '@/modules/courses-children/components/CourseTimeState.vue';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    Icon,
    Typography,
    ChipState,
    // CourseTimeState,
    CourseStatusState
  },

  props: ['hasSignUp', 'timeIsUp'],

  data() {
    return {
      abouts: [
        'This course is for those wishing to achieve a nationally recognised qualification in the installation and maintenance of Solar Photovoltaic systems. This qualification is based upon the National Occupational Standards as published by industry and is recognised by the Microgeneration Certification Scheme registration bodies as an acceptable qualification to join the scheme.'
      ],
      prerequisites: [
        'It is a requirement that any learner wishing to undertake this qualification should hold a 18th Edition Training + Assessment and Initial Verification qualification.'
      ],
      courseAims: [
        'Know the health and safety risks and safe systems of work associated with solar photovoltaic system installation work',
        'Know the requirements of the relevant regulations/ standards relating to practical installation, testing and commissioning activities for solar photovoltaic system installation work',
        'Know the fundamental differences between a.c and d.c circuits within solar photovoltaic systems',
        'Know the purpose of solar photovoltaic system components',
        'Know the types, silicon characteristics and typical conversion efficiencies of solar photovoltaic modules',
        'Know the fundamental design principles used to determine solar photovoltaic system module array size and position requirements.',
        'Know the preparatory work required for solar photovoltaic system installation work',
        'Know the layouts and the requirements for installing solar photovoltaic module arrays',
        'Know solar photovoltaic system d.c and a.c circuit installation layouts within the scope of the',
        'Relevant Engineering Recommendation for grid tied systems',
        'Know solar photovoltaic system protection techniques and components',
        'Know the requirements to test and commission solar photovoltaic systems',
        'Know the requirements to handover solar photovoltaic systems',
        'Know how to plan and prepare for the installation of a solar photovoltaic system',
        'Know how to install solar photovoltaic system components',
        'Know how to inspect and test a new solar photovoltaic system installation',
        'Know how to commission a new solar photovoltaic system installation',
        'Know how to handover a new solar photovoltaic system installation',
        'Know the requirements for the routine inspection, service and maintenance of solar photovoltaic system installations',
        'Know how to diagnose faults in solar photovoltaic system installations',
        'Know how to rectify faults in solar photovoltaic systems',
        'now how to undertake the routine service and maintenance of a solar photovoltaic system installation',
        'Know how to undertake fault diagnosis work on solar photovoltaic system installations',
        'Know how to undertake fault rectification work on solar photovoltaic system installations',
      ],
      attainSkills: [
        'Obtain HIES Consumer code certificate',
        'Join MCS as a certified installer',
        'Offer solar pv installation & maintenance services',
      ],
    };
  },
  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),
  },

  methods: {
    getStatusLabel() {
      if (this.hasSignUp) {
        return "You're Enrolled";
      }
      return "You're Pre-Enrolled";
    }
  }
});
</script>
<style lang="scss" scoped>
.air-source-template {
  padding-bottom: 2rem;
  box-sizing: border-box;

  &__banner-container {
    max-height: 235px;
    min-height: 235px;
    border-radius: 8px;
    overflow: hidden;
  }
  &__img {
    object-fit: cover;
    height: 100%;
    width:100%;
  }
  &__wrapper.grid {
    grid-template-columns: repeat(auto-fill, minmax(255px, 1fr));
    padding-left: 1rem;
    box-sizing: border-box;
  }
}
</style>
