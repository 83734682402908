<template>
    <div class="right-sidebar-course-details flex-column jc-sb default-card">
      <div class="overflow-y-auto flex-column">
        <section class="right-sidebar-course-details__wrapper border-bottom flex-column w-100">
          <Typography variant="custom" textSize="18px">Sign Up To This Course Today</Typography>
          <div class="flex-column gap-half">
            <Typography variant="p">Entry requirements</Typography>
            <Typography variant="p" textColor="rgba(12, 15, 74, 0.50)" textWeight="500">
              To be eligible for this course, learners must have all the prerequisites.
            </Typography>
          </div>
          <div class="w-100 flex-row ai-c jc-fe">
            <ActionItemComponent
              label="Check eligibility"
              :isReverse="true"
              fontColor="#FFA500"
              icon="arrow-right.svg"/>
          </div>
        </section>
        <section class="right-sidebar-course-details__wrapper border-bottom flex-column w-100">
            <Typography variant="p">Summary</Typography>
            <div class="flex-column">
              <div class="flex-row ai-c gap-half">
                <Icon iconName="yellow-calendar-icon.svg"/>
                <Typography variant="p" textWeight="500">Flexible start date</Typography>
              </div>
              <!-- <div class="flex-row ai-c gap-half">
                <Icon iconName="yellow-duration-icon.svg"/>
                <Typography variant="p" textWeight="500">Duration 4 days</Typography>
              </div> -->
              <div class="flex-row ai-c gap-half">
                <Icon iconName="yellow-mic-icon.svg"/>
                <Typography variant="p" textWeight="500">English</Typography>
              </div>
              <div class="flex-row ai-c gap-half">
                <Icon iconName="yellow-domain-icon.svg"/>
                <Typography variant="p" textWeight="500">On-site lessons</Typography>
              </div>
              <!-- <div class="flex-row ai-c gap-half">
                <Icon iconName="yellow-location-icon.svg"/>
                <Typography variant="p" textWeight="500">West Midlands residence</Typography>
              </div> -->
              <div class="flex-row ai-c gap-half">
                <Icon iconName="yellow-certificate-icon.svg"/>
                <Typography variant="p" textWeight="500">Certification on completion</Typography>
              </div>
            </div>
        </section>
        <section class="flex-column">
          <div class="flex-row ai-c jc-sb" :class="{'ai-fs': currentCourse.courseFee !== 'FREE'}">
            <div class="flex-column gap-0">
              <Typography variant="p">Course fee</Typography>
              <Typography
                v-if="currentCourse.courseFee !== 'FREE'"
                variant="custom"
                textSize="10px"
                textColor="rgba(12, 15, 74, 0.50)"
                lineHeight="18px">
                (NOTE: Payable directly with the training provider)
              </Typography>
            </div>
            <ChipState
              :hideIcon="true"
              textColor="#FAA100"
              :label="$filters.formatToCurrency('220')"
              :labelSpan="currentCourse.courseFee === 'FREE' ? currentCourse.courseFee : $filters.formatToCurrency(currentCourse.courseFee)"
              :crashLabel="true"/>
          </div>
          <div class="flex-row ai-c jc-sb">
            <Typography variant="p">
              Booking fee <span style="font-weight: 400; font-size: 10px">(inc. VAT)</span>
            </Typography>
            <Typography variant="p">
              {{ $filters.formatToCurrency(currentCourse.serviceFee) }}
            </Typography>
          </div>
        </section>
        <section class="flex-column gap-half" style="padding-top: 1.5rem">
          <TermsAgreement
            @on-change-state="onChangeTermsConditionsAgreement"
            tag="I have read and agree with the" spanTag="Terms of Service & Privacy Policy"/>
          <TermsAgreement
            @on-change-state="onChangeCancelationPolicyAgreement"
            tag="I have read and agree with the" spanTag="Cancellation Policy"/>
        </section>
        <!-- <MemberCounter
          v-if="members.length"
          :visibleMembers="visibleMembers"
          :remainingMembersCount="remainingMembersCount"/> -->
      </div>
      <section class="flex-column w-100 gap-half">
        <Button
          class="w-100"
          buttonText="register interest for course"
          :isActive="true"
          activeColor="#FFF"
          activeFontColor="#4F55F0"
          borderColor="#4F55F0"/>
        <Button
          class="w-100"
          buttonText="secure my spot"
          @handle-click="goToBilling"
          :isActive="isFormReady"/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import ActionItemComponent from '@/core/components/ui/ActionItemComponent.vue';
import Button from '@/core/components/ui/Button.vue';
import ChipState from '@/core/components/ui/ChipState.vue';
import Icon from '@/core/components/ui/Icon.vue';
import TermsAgreement from '@/core/components/ui/TermsAgreement.vue';
import Typography from '@/core/components/ui/Typography.vue';
// import MemberCounter from '@/modules/courses-children/components/MemberCounter.vue';
import { OPPORTUNITY_BILLING_ROUTE } from '@/modules/opportunity-billing/routes';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    Typography,
    ActionItemComponent,
    Icon,
    ChipState,
    TermsAgreement,
    // MemberCounter,
    Button
  },

  emits: ['on-agreement-change'],

  data() {
    return {
      isFormReady: false,
      agreedToTerms: false,
      agreedToCancellationPolicy: false,
      members: [
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
        {
          img: '@/assets/images/sample-avatar.jpg'
        },
      ],
    };
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),

    visibleMembers() {
      return this.members.slice(0, 5);
    },
    remainingMembersCount() {
      return Math.max(0, this.members.length - 5);
    }
  },

  methods: {
    onChangeTermsConditionsAgreement(state) {
      this.agreedToTerms = state;
      this.checkIfFormIsReady();
    },

    onChangeCancelationPolicyAgreement(state) {
      this.agreedToCancellationPolicy = state;
      this.checkIfFormIsReady();
    },

    checkIfFormIsReady() {
      const { agreedToTerms, agreedToCancellationPolicy } = this;
      this.isFormReady = agreedToTerms && agreedToCancellationPolicy;

      this.$emit('on-agreement-change', this.isFormReady);
    },

    goToBilling() {
      this.$router.push({
        name: OPPORTUNITY_BILLING_ROUTE
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.right-sidebar-course-details {
    max-height: unset !important;
    box-shadow: 0px 0px 8px 0px rgba(12, 15, 74, 0.08) !important;

    &__wrapper.border-bottom {
        padding-bottom: 1rem;
        box-sizing: border-box;
        border-bottom: 1px solid rgba(12, 15, 74, 0.10);
    }
}

@media screen and (min-height: 1145px) {
  .right-sidebar-course-details {
    max-height: 885px !important;
  }
}
</style>
