<template>
    <div class="course-detail-card default-card flex-column">
      <section class="flex-row ai-c jc-sb">
        <ChipState
          :hideIcon="true"
          textColor="#1FB27A"
          label="Days"
          :labelSpan="detail?.days"
          :isReverse="true"/>
        <ChipState
            iconCustomPath="icons/courses-icons"
            iconName="location-filled-blue.svg"
            textColor="#264FD5"
            :label="detail?.sight"/>
      </section>
      <section class="flex-column gap-half">
        <div class="flex-row ai-c gap-half">
          <Icon iconName="opportunities-icon.svg"/>
          <Typography variant="h6">{{ detail?.title }}</Typography>
        </div>
        <Typography variant="p" lineHeight="18px" textWeight="400" textColor="rgba(12, 15, 74, 0.50)">
          {{ detail?.description }}
        </Typography>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';

import ChipState from '@/core/components/ui/ChipState.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';

export default defineComponent({
  components: {
    ChipState,
    Typography,
    Icon
  },

  props: ['detail']
});
</script>
<style lang="scss" scoped>
.course-detail-card {
    max-height: 180px !important;
    min-height: 180px !important;
}
</style>
