<template>
    <div
      :class="{'no-padding-bottom': currentCourse.isUpcoming}"
      class="course-details flex-row jc-sb w-100 h-100 gap-3 default-layout-desktop-padding">
      <section class="course-details__container flex-column jc-sb w-100 h-100">
        <div
          @click="onBack"
          class="course-details__back-card flex-row ai-c jc-c gap-half w-auto cursor-pointer">
          <Icon iconName="arrow-left.svg"/>
          <Typography variant="custom" textSize="12px" textWeight="600" whiteSpace="nowrap">
            Back to Course results
          </Typography>
        </div>
        <div class="overflow-y-auto">
          <component :is="templateComponent" :hasSignUp="hasSavedDate"></component>
        </div>
        <div class="course-details__footer grid ai-c w-100" v-if="!currentCourse.isUpcoming">
          <Button
            class="w-100"
            buttonText="register interest for course"
            :isActive="true"
            activeColor="#FFF"
            activeFontColor="#4F55F0"
            borderColor="#4F55F0"/>
          <Button
            @handle-click="goToBilling"
            class="w-100"
            buttonText="secure my spot"
            :isActive="isFormReady"/>
        </div>
      </section>
      <section class="course-details__sidebar">
        <RightSidebarSteps
          v-if="hasSavedDate || hasNoSavedDate"
          :currentCourse="currentCourse"
          :hasSavedDate="hasSavedDate"
          :hasNoSavedDate="hasNoSavedDate"
          :hasCompleted="hasCompleted"/>
        <RightSidebarCourseDetails v-else @on-agreement-change="onAgreementChange"/>
      </section>
    </div>
</template>
<script>
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

import Button from '@/core/components/ui/Button.vue';
import Icon from '@/core/components/ui/Icon.vue';
import Typography from '@/core/components/ui/Typography.vue';
import AirSourceHeatPumpTemplate from '@/modules/courses-children/components/course-templates/AirSourceHeatPumpTemplate.vue';
import EnergyEfficiencyForDomesticHeatingTemplate from '@/modules/courses-children/components/course-templates/EnergyEfficiencyForDomesticHeatingTemplate.vue';
import SolarPvInstallationAndMaintenanceTemplate from '@/modules/courses-children/components/course-templates/SolarPvInstallationAndMaintenanceTemplate.vue';
import UnventedHotWaterTemplate from '@/modules/courses-children/components/course-templates/UnventedHotWaterTemplate.vue';
import WaterRegulationsTemplate from '@/modules/courses-children/components/course-templates/WaterRegulationsTemplate.vue';
import RightSidebarCourseDetails from '@/modules/courses-children/components/RightSidebarCourseDetails.vue';
import RightSidebarSteps from '@/modules/courses-children/components/RightSidebarSteps.vue';
import { courseDetails } from '@/modules/courses-children/data';
import { OPPORTUNITY_BILLING_ROUTE } from '@/modules/opportunity-billing/routes';
import { OPPORTUNITY_STORE } from '@/store/modules/opportunity';

export default defineComponent({
  components: {
    AirSourceHeatPumpTemplate,
    EnergyEfficiencyForDomesticHeatingTemplate,
    SolarPvInstallationAndMaintenanceTemplate,
    UnventedHotWaterTemplate,
    WaterRegulationsTemplate,

    Icon,
    Typography,
    Button,
    RightSidebarCourseDetails,
    RightSidebarSteps
  },
  data() {
    return {
      isFormReady: false,
      courseDetails,

      // INITIALIZE TEMPLATE COMPONENTS
      AirSourceHeatPumpTemplate,
      EnergyEfficiencyForDomesticHeatingTemplate,
      SolarPvInstallationAndMaintenanceTemplate,
      UnventedHotWaterTemplate,
      WaterRegulationsTemplate,

      // TEMPLATE COMPONENT TO PASS AS A PROP
      templateComponent: AirSourceHeatPumpTemplate,

      // TEMPORARY STATE
      hasSignUp: true,
    };
  },

  computed: {
    ...mapGetters(OPPORTUNITY_STORE, ['currentCourse', 'courses']),

    hasNoSavedDate() {
      return this.currentCourse.isUpcoming && !this.currentCourse.hasSelectedDate;
    },

    hasSavedDate() {
      return this.currentCourse.isUpcoming && this.currentCourse.hasSelectedDate;
    },

    hasCompleted() {
      return this.currentCourse.isCompleted;
    },
  },

  created() {
    if (!this.currentCourse) {
      window.location.href = '/courses-opportunities';
    } else {
      this.getCourseTemplate();
    }
  },

  methods: {
    onBack() {
      this.$router.go(-1);
    },

    getCourseTemplate() {
      const course = this.courseDetails.find((detail) => detail.id === this.currentCourse.id);
      this.templateComponent = course ? course.templateComponentName : AirSourceHeatPumpTemplate;
    },

    onAgreementChange(state) {
      this.isFormReady = state;
    },

    goToBilling() {
      this.$router.push({
        name: OPPORTUNITY_BILLING_ROUTE
      });
    },
  },
});
</script>
<style lang="scss" scoped>
.course-details {
  &__back-card {
    border-radius: 100%;
    padding: 2px 3px;
    background: #FFF;
    box-sizing: border-box;
    max-width: 159px;
    border-radius: 100px;
  }

  &__footer.grid {
    height: auto;
    padding-top: 1.5rem;
    box-sizing: border-box;
    border-top: 1px solid #C5D2F2;
    grid-template-columns: 1fr 2fr;
  }

  &__sidebar {
    padding-top: .5rem;
  }
}
.no-padding-bottom {
  padding-bottom: 0 !important;
}
</style>
